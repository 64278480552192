import axios from 'axios'
import store from '@/store'

const axiosToken = axios.create()

axiosToken.interceptors.request.use(
  (config) => {
    // リクエスト前にトークンをヘッダーに付ける
    config.headers['Authorization'] = `Bearer ${store.getters.accessToken}`
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// 同時APIリクエストで複数回リフレッシュしないようにするため
let refreshTokenPromise // リフレッシュ中保持用

axiosToken.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    console.log(error.response)

    if (error.response && error.response.status === 401) {
      // Token認証エラー
      console.log('**refreshTokenPromise***', refreshTokenPromise)
      if (!refreshTokenPromise) {
        // RefreshTokenでAccessTokenリフレッシュ
        console.log('*** token refresh ***')
        refreshTokenPromise = new Promise((resolve, reject) => {
          axios
            .post(
              '/api/refresh_token',
              {},
              {
                headers: {
                  Authorization: `Bearer ${store.getters.refreshToken}`,
                },
              }
            )
            .then((response) => {
              console.log(response)
              // storeに新しい情報を保存
              store.dispatch('setUserInfo', response.data)
              resolve('refreshed')
            })
            .catch((error) => {
              console.log('*** refresh failed ***')
              reject(error)
            })
            .finally(() => {
              refreshTokenPromise = null
            })
        })
      }

      return refreshTokenPromise
        .then(() => {
          // 新しいAccessTokenでリトライ
          console.log('*** retry ***')
          let originalConfig = error.response.config
          originalConfig.headers[
            'Authorization'
          ] = `Bearer ${store.getters.accessToken}`
          return axios.request(originalConfig)
        })
        .catch((error) => {
          if (store.getters.userId) {
            alert('Token認証エラー 再ログインしてください。')
            store.dispatch('logout')
          }
          return Promise.reject(error)
        })
    }
    return Promise.reject(error)
  }
)

export default axiosToken
