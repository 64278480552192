import axios from 'axios'
import router from '@/router'

const axiosDC = axios.create()
axiosDC.defaults.withCredentials = true

axiosDC.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    console.log(error.response)
    if (
      error.response &&
      error.response.status === 401 &&
      router.currentRoute.name != 'consumer-login'
    ) {
      alert('セッション期限切れ 再ログインしてください。')
      sessionStorage.removeItem('consumerName')
      router.push('/consumer/login')
    }
    if (error.response && error.response.status === 503) {
      alert('サービス時間外です。')
      sessionStorage.removeItem('consumerName')
      router.push('/consumer/login')
    }
    return Promise.reject(error)
  }
)

export default axiosDC