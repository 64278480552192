<template>
  <b-navbar toggleable="sm" type="dark" variant="csub">
    <b-navbar-brand to="/">PLR+</b-navbar-brand>
    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav class="ml-auto">
        <b-nav-item
          v-if="roleId === 0 || roleId === 1"
          to="/wfp-mng"
          active-class="link-active"
          exact
          >研究プロジェクト管理</b-nav-item
        >
        <b-nav-item
          v-if="roleId === 0 || roleId === 2"
          to="/customer-mng"
          active-class="link-active"
          exact
          >データ取引会員管理</b-nav-item
        >
        <b-nav-item
          v-if="roleId === 0 || roleId === 2"
          to="/account-opener-mng"
          active-class="link-active"
          exact
          >口座開設者管理</b-nav-item
        >
        <b-nav-item
          v-if="roleId === 0 || roleId === 2"
          to="/account-opening-mng"
          active-class="link-active"
          exact
          >口座一括開設処理</b-nav-item
        >
        <b-nav-item
          v-if="roleId === 0 || roleId === 1 || roleId === 2 || roleId === 3"
          to="/data-contract-mng"
          active-class="link-active"
          exact
          >データ取引契約管理</b-nav-item
        >
        <b-nav-item
          v-if="roleId === 0 || roleId === 1 || roleId === 2"
          to="/catalog-search"
          active-class="link-active"
          exact
          >カタログ検索</b-nav-item
        >
        <b-nav-item
          v-if="roleId === 0"
          to="/user-mng"
          active-class="link-active"
          exact
          >ユーザ管理</b-nav-item
        >
        <b-nav-item v-on:click="logout()">LOGOUT</b-nav-item>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>
<script>
export default {
  computed: {
    roleId() {
      return this.$store.getters.roleId
    },
  },
  methods: {
    logout() {
      this.$store.dispatch('logout')
    },
  },
}
</script>
