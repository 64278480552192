import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'
import axios from 'axios'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    accessToken: null,
    refreshToken: null,
    userId: null,
    name: null,
    roleId: null,
    pxrSync: {
      accountOpening: false,
    },
    linkageUserId: null,
    linkageWfCode: null,
  },
  getters: {
    accessToken: (state) => state.accessToken,
    refreshToken: (state) => state.refreshToken,
    userId: (state) => state.userId,
    name: (state) => state.name,
    roleId: (state) => state.roleId,
    pxrSyncAccountOpening: (state) => state.pxrSync.accountOpening,
    linkageWfCode: (state) => state.linkageWfCode,
    linkageUserId: (state) => state.linkageUserId,
  },
  mutations: {
    setUserInfo(state, userInfo) {
      state.accessToken = userInfo.access_token
      state.refreshToken = userInfo.refresh_token
      state.userId = userInfo.id
      state.name = userInfo.name
      state.roleId = userInfo.roleId
    },
    cleareUserInfo(state) {
      state.accessToken = null
      state.refreshToken = null
      state.userId = null
      state.name = null
      state.roleId = null
    },
    setPxrSync(state, target) {
      state.pxrSync[target] = true
    },
    clearPxrSync(state) {
      state.pxrSync.accountOpening = false
    },
    setLinkageInfo(state, linkageInfo) {
      state.linkageWfCode = linkageInfo.wfCode
      state.linkageUserId = linkageInfo.userId
    },
    clearLinkageInfo(state) {
      state.linkageWfCode = null
      state.linkageUserId = null
    },
  },
  actions: {
    login({ commit }, authData) {
      let params = new URLSearchParams()
      params.append('username', authData.username)
      params.append('password', authData.password)
      return new Promise((resolve, reject) => {
        axios
          .post('/api/login', params)
          .then((response) => {
            commit('setUserInfo', response.data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    logout({ commit }) {
      commit('cleareUserInfo')
      commit('clearPxrSync')
      router.push('/login')
    },
    setUserInfo({ commit }, data) {
      commit('setUserInfo', data)
    },
    setPxrSync({ commit }, target) {
      commit('setPxrSync', target.target)
    },
    setLinkageInfo({ commit }, linkageInfo) {
      commit('setLinkageInfo', linkageInfo)
    },
    clearLinkageInfo({ commit }) {
      commit('clearLinkageInfo')
    },
  },
  plugins: [
    // reload時にも保持するようSessionStorageに持つ
    createPersistedState({
      key: 'plrApp',
      storage: window.sessionStorage,
    }),
  ],
})
