import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import './assets/scss/bootstrap-custom.scss'
import axios from 'axios'
import axiosToken from '@/axios-token'
import axiosDC from '@/axios-dataconnector'
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
import VueCookies from 'vue-cookies'
import VueMeta from 'vue-meta'

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(Viewer)
Vue.use(VueCookies)
Vue.use(VueMeta)
Vue.prototype.$axios = axios
Vue.prototype.$axiosToken = axiosToken
Vue.prototype.$axiosDC = axiosDC

Vue.config.productionTip = false

// API Server
axios.defaults.baseURL = axiosToken.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT
axiosDC.defaults.baseURL = process.env.VUE_APP_DC_API_ENDPOINT

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
