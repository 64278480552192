<template>
  <div id="app">
    <navbar v-if="$route.meta.isNav"></navbar>
    <router-view />
  </div>
</template>

<script>
import Navbar from '@/components/common/Navbar.vue'
export default {
  components: {
    Navbar,
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
html {
  font-size: 62.5%;
}
.account-opening h3,
.account-opening h4,
.account-opening h5 {
  font-weight: 700;
}
label {
  margin-bottom: 0 !important;
}
.table {
  white-space: nowrap;
}
.table .cell-break {
  white-space: normal;
  min-width: 350px;
}
.table .cell-break-small {
  white-space: normal;
  min-width: 180px;
}
.table thead th {
  vertical-align: middle !important;
  text-align: center !important;
  padding: 0.5rem;
  border-top: none;
  font-size: small;
}
.table .custom-select {
  width: max-content;
}
.form-control:disabled {
  border: none;
}
.box-large .custom-control-label {
  padding-left: 1rem;
}
.box-large .custom-control-label::before,
.box-large .custom-control-label::after {
  top: 0.1rem;
  left: -2.5rem;
  width: 1.8rem;
  height: 1.8rem;
  margin-left: 1.25rem;
}
.alert {
  white-space: pre-line;
}
nav a {
  font-weight: bold;
}
.navbar {
  padding: 0 0 0 1rem !important;
}
.navbar-brand {
  padding-left: 1rem;
  font-size: 24px !important;
}
.navbar-text {
  color: #fff !important;
  font-weight: bold;
}
.nav-item {
  text-align: center;
}
.nav-link {
  padding: 1.2rem 1rem !important;
}
.link-active {
  background-color: #125294;
  color: #fff !important;
}
</style>
