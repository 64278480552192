import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import VueGtag from 'vue-gtag'

Vue.use(VueRouter)

// 0:admin, 1:iLdi, 2:consortium, 3:treasurer
const routes = [
  {
    path: '/',
    name: 'home',
    beforeEnter(to, from, next) {
      // roleIdによってlogin後の画面を分ける
      if (store.getters.roleId === 0 || store.getters.roleId === 1) {
        //admin or iLDi
        next('/wfp-mng')
      } else if (store.getters.roleId === 2) {
        // consortium
        next('/customer-mng')
      } else if (store.getters.roleId === 3) {
        // treasurer
        next('/data-contract-mng')
      } else {
        next('/login')
      }
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login.vue'),
  },
  {
    path: '/wfp-mng',
    name: 'wfp-mng',
    component: () => import('@/views/WfProviderMng.vue'),
    beforeEnter(to, from, next) {
      // admin, iLDiに権限あり
      if (store.getters.roleId === 0 || store.getters.roleId === 1) {
        next()
      } else {
        alert('権限がありません。')
        next('/')
      }
    },
    meta: { isNav: true },
  },
  {
    path: '/customer-mng',
    name: 'customer-mng',
    component: () => import('@/views/CustomerMng.vue'),
    beforeEnter(to, from, next) {
      // admin, consortiumに権限あり
      if (store.getters.roleId === 0 || store.getters.roleId === 2) {
        next()
      } else {
        alert('権限がありません。')
        next('/')
      }
    },
    meta: { isNav: true },
  },
  {
    path: '/account-opener-mng',
    name: 'account-opener-mng',
    component: () => import('@/views/AccountOpenerMng.vue'),
    beforeEnter(to, from, next) {
      // admin, consortiumに権限あり
      if (store.getters.roleId === 0 || store.getters.roleId === 2) {
        next()
      } else {
        alert('権限がありません。')
        next('/')
      }
    },
    meta: { isNav: true },
  },
  {
    path: '/account-opening-mng',
    name: 'account-opening-mng',
    component: () => import('@/views/AccountOpeningMng.vue'),
    beforeEnter(to, from, next) {
      // admin, consortiumに権限あり
      if (store.getters.roleId === 0 || store.getters.roleId === 2) {
        next()
      } else {
        alert('権限がありません。')
        next('/')
      }
    },
    meta: { isNav: true },
  },
  {
    path: '/data-contract-mng',
    name: 'data-contract-mng',
    component: () => import('@/views/DataContractMng.vue'),
    beforeEnter(to, from, next) {
      // admin, iLdi, consortium, treasurerに権限あり
      if (
        store.getters.roleId === 0 ||
        store.getters.roleId === 1 ||
        store.getters.roleId === 2 ||
        store.getters.roleId === 3
      ) {
        next()
      } else {
        alert('権限がありません。')
        next('/')
      }
    },
    meta: { isNav: true },
  },
  {
    path: '/catalog-search',
    name: 'catalog-search',
    component: () => import('@/views/CatalogSearch.vue'),
    beforeEnter(to, from, next) {
      // admin, iLdi, consortiumに権限あり
      if (
        store.getters.roleId === 0 ||
        store.getters.roleId === 1 ||
        store.getters.roleId === 2
      ) {
        next()
      } else {
        alert('権限がありません。')
        next('/')
      }
    },
    meta: { isNav: true },
  },
  {
    path: '/catalog-details/:workflowCode',
    name: 'catalog-details',
    component: () => import('@/views/CatalogDetails.vue'),
    beforeEnter(to, from, next) {
      // admin, iLdi, consortiumに権限あり
      if (
        store.getters.roleId === 0 ||
        store.getters.roleId === 1 ||
        store.getters.roleId === 2
      ) {
        next()
      } else {
        alert('権限がありません。')
        next('/')
      }
    },
    meta: { isNav: true },
    props: true,
  },
  {
    path: '/zoom-window',
    name: 'zoom-window',
    component: () => import('@/views/ZoomWindow.vue'),
  },
  {
    // 個人用口座開設ページ
    path: '/account-opening',
    component: () => import('@/views/account-opening/Index.vue'),
    children: [
      {
        path: '',
        name: 'account-opening-top',
        component: () => import('@/views/account-opening/Top.vue'),
        meta: { isPublic: true },
      },
      {
        path: 'agreement',
        name: 'agreement',
        component: () => import('@/views/account-opening/Agreement.vue'),
        meta: { isPublic: true },
      },
      {
        path: 'application-form',
        component: () =>
          import('@/views/account-opening/application-form/Index.vue'),
        children: [
          {
            path: '',
            name: 'application-form',
            component: () =>
              import('@/views/account-opening/application-form/Form.vue'),
            meta: { isPublic: true },
            beforeEnter(to, from, next) {
              // 同意画面と確認画面からのみ遷移できるように
              if (
                from.name === 'agreement' ||
                from.name === 'application-confirm'
              ) {
                next()
              } else {
                next('/account-opening')
              }
            },
          },
          {
            path: 'confirm',
            name: 'application-confirm',
            component: () =>
              import('@/views/account-opening/application-form/Confirm.vue'),
            meta: { isPublic: true },
            beforeEnter(to, from, next) {
              // 入力Formからのみ遷移できるように
              if (from.name === 'application-form') {
                next()
              } else {
                next('/account-opening')
              }
            },
          },
        ],
      },
      {
        path: 'application-message',
        name: 'application-message',
        component: () =>
          import('@/views/account-opening/ApplicationMessage.vue'),
        props: true,
        meta: { isPublic: true },
      },
      {
        path: 'application-accept/:applicationId',
        name: 'application-accept',
        component: () =>
          import('@/views/account-opening/ApplicationAccept.vue'),
        meta: { isPublic: true },
      },
    ],
  },
  {
    // eKYC個人用口座開設ページ
    path: '/account-opening-ekyc',
    component: () => import('@/views/account-opening-ekyc/Index.vue'),
    children: [
      {
        path: '',
        name: 'top-ekyc',
        component: () => import('@/views/account-opening/Top.vue'),
        meta: { isPublic: true },
      },
      {
        path: 'agreement',
        name: 'agreement-ekyc',
        component: () => import('@/views/account-opening/Agreement.vue'),
        meta: { isPublic: true },
      },
      {
        path: 'application-form',
        component: () =>
          import('@/views/account-opening-ekyc/application-form/Index.vue'),
        children: [
          {
            path: '',
            name: 'application-form-ekyc',
            component: () =>
              import('@/views/account-opening-ekyc/application-form/Form.vue'),
            meta: { isPublic: true },
            beforeEnter(to, from, next) {
              // 同意画面と確認画面からのみ遷移できるように
              if (
                from.name === 'agreement-ekyc' ||
                from.name === 'application-confirm-ekyc'
              ) {
                next()
              } else {
                next('/account-opening-ekyc')
              }
            },
          },
          {
            path: 'confirm',
            name: 'application-confirm-ekyc',
            component: () =>
              import(
                '@/views/account-opening-ekyc/application-form/Confirm.vue'
              ),
            meta: { isPublic: true },
            beforeEnter(to, from, next) {
              // 入力Formからのみ遷移できるように
              if (from.name === 'application-form-ekyc') {
                next()
              } else {
                next('/account-opening-ekyc')
              }
            },
          },
        ],
      },
      {
        path: 'submit-documents/:applicationId/:publicId/:planId',
        name: 'submit-documents-ekyc',
        component: () =>
          import('@/views/account-opening-ekyc/SubmitDocuments.vue'),
        props: true,
        meta: { isPublic: true },
        beforeEnter(to, from, next) {
          // 確認画面からのみ遷移できるように
          if (from.name === 'application-confirm-ekyc') {
            next()
          } else {
            next('/account-opening-ekyc')
          }
        },
      },
      {
        path: 'application-accept/:applicationId',
        name: 'application-accept-ekyc',
        component: () =>
          import('@/views/account-opening/ApplicationAccept.vue'),
        meta: { isPublic: true },
      },
    ],
  },
  {
    path: '/user-mng',
    name: 'user-mng',
    component: () => import('@/views/UserMng.vue'),
    beforeEnter(to, from, next) {
      // adminに権限あり
      if (store.getters.roleId === 0) {
        next()
      } else {
        alert('権限がありません。')
        next('/')
      }
    },
    meta: { isNav: true },
  },
  {
    // DataConnector データ取引会員用ページ
    path: '/consumer',
    component: () => import('@/views/consumer/Index.vue'),
    children: [
      {
        path: '',
        redirect: 'login',
      },
      {
        path: 'login',
        name: 'consumer-login',
        component: () => import('@/views/consumer/Login.vue'),
        meta: { isDC: true },
      },
      {
        path: 'contracted-list',
        name: 'contracted-list',
        component: () => import('@/views/consumer/ContractedList.vue'),
        meta: { isDC: true },
      },
    ],
  },
  {
    // 上のいずれともマッチしなかった場合
    path: '*',
    component: () => import('@/views/NotFound.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

// ページ遷移前にTokenをチェックする
router.beforeEach((to, from, next) => {
  if (to.meta.isPublic || to.meta.isDC) {
    // isPublic:true, isDC:true Tokenチェック不要
    next()
  } else {
    if (to.path === '/login') {
      if (store.getters.accessToken) {
        next('/')
      } else {
        next()
      }
    } else {
      if (store.getters.accessToken) {
        next()
      } else {
        next('/login')
      }
    }
  }
})

Vue.use(
  VueGtag,
  {
    config: { id: 'G-NMTQ1BJRZM' },
  },
  router
)

export default router
